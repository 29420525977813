import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import SocialShare from '../components/social-share';
import RecentPosts from '../components/Recent-posts';
import Comments from '../components/Comments';
import MailChimpBanner from '../components/Mailchimp-banner';
import PromoBanner from '../components/PromoBanner';
import LHTBanner from '../components/LHTBanner';
import ContentBanner from '../components/ContentBanner';
import Sidebar from '../components/Sidebar';
import Img from 'gatsby-image';
import BuyMeCoffee from '../components/BuyMeCoffee';
import InlineAffiliateAd from '../components/InlineAffiliateAd';
export default class BlogPostTemplate extends Component {
	constructor(props) {
		super(props);
		this.frontmatter = props.data.mdx.frontmatter;
		this.disqusProps = {
			shortname: 'HowToVietnamese',
			config: {
				identifier: props.pageContext.id, // identify of the post
				title: this.frontmatter.title, // title of the post
			},
		};
		this.category = this.frontmatter.category;
		this.title = `${this.frontmatter.title}`;
		this.path = this.frontmatter.path;
		this.id = props.pageContext.id;
		this.excerpt = this.frontmatter.excerpt;
		this.keywords = this.frontmatter.keywords;
		this.image = this.frontmatter.image;
		this.body = props.data.mdx.body;
		this.url = `https://howtovietnamese.com${this.path}`;
		this.canonicalUrl = null;
		if (
			this.path.includes('how-long-to-learn-vietnamese') ||
			this.path.includes('vietnamese-alphabet-tones')
		) {
			this.canonicalUrl = 'https://langi.app/blog' + this.path;
		}
	}

	render() {
		return (
			<Layout bodyclassName="page-service">
				<SEO
					title={this.title}
					keywords={this.keywords}
					image={this.image.childImageSharp.fixed.src}
					description={this.excerpt}
					url={this.url}
					canonicalUrl={this.canonicalUrl}
				/>
				{/* <PromotionPopup /> */}
				<PromoBanner />
				<div
					id="postContainer"
					className="contentContainer margin-from-header bg-white"
				>
					<div className="container mt-0 mt-md-2">
						<div className="row justify-content-center">
							<div className="col-12 col-md-10 pr-0 pl-0">
								<div className="post-single bg-white mb-4 pt-4 pt-md-8 pl-2 pr-2 pl-md-5 pr-md-5">
									{/* TITLE */}
									<h1>{this.title}</h1>
								</div>
							</div>
							<div className="col-12 col-md-10 pl-0 pr-0">
								{/* IMAGE*/}
								<Img
									fluid={this.image.childImageSharp.fluid}
									alt={this.title}
								/>
								<ContentBanner isTransparent="true"></ContentBanner>
							</div>
						</div>

						<div className="row justify-content-center mt-4 mt-md-8">
							{/* <div className="col-12 col-md-1 pr-0 pl-0"></div> */}

							{/* sidebar */}
							{/* <div className="col-12 col-md-3 pl-0 pr-0 pl-md-0 pr-md-0 hide-on-mobile">
                <div className="container pl-0 pr-0">
                  <Sidebar currentPostID={this.id} />
                </div>
              </div> */}

							{/* content body */}
							<div className="col-12 col-md-7 pr-0 pl-0 pl-md-0">
								<div className="post-single bg-white pb-0 pb-md-2 pl-2 pr-2 pl-md-0 pr-md-0">
									<MDXRenderer>{this.body}</MDXRenderer>

									{/* Buy me a Coffee */}
									<div className="border rounded bmac-container box-shadow mt-6 mt-md-8 pt-4 pb-4">
										<div className="row justify-content-center">
											<p className="font-weight-bold text-align-center ml-3 mr-3 ml-md-0 mr-md-0 mb-2">
												Loving the content? Support me with a Coffee ❤️
											</p>
											<div className="col-9 col-md-5">
												<BuyMeCoffee></BuyMeCoffee>
											</div>
										</div>
									</div>

									{/* <InlineAffiliateAd id="page_bottom" /> */}

									<div className="mt-4 mt-md-4">
										<SocialShare
											title={this.title}
											excerpt={this.excerpt}
											path={this.path}
										/>
										<Comments href={this.url}></Comments>
										{/* Recent Posts */}
										{/* <div className="hide-on-mobile mb-4">
                      <RecentPosts currentPostID={this.id} />
                    </div> */}
										<MailChimpBanner />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export const query = graphql`
	query ($id: String!) {
		mdx(id: { eq: $id }) {
			body
			frontmatter {
				title
				path
				category
				excerpt
				keywords
				image {
					childImageSharp {
						fluid(maxWidth: 900) {
							...GatsbyImageSharpFluid
						}
					}
					childImageSharp {
						fixed(width: 1200) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`;
