import React from "react";
import BuyMeCoffee from "./BuyMeCoffee";
import Strawpoll from "./Strawpoll";

function Sidebar(props) {
  const currentPostID = props.currentPostID;

  return (
    <div className="sidebar">
      {/* mailchimp email field */}
      {/* recent posts */}
      {/* <RecentPosts currentPostID={currentPostID} /> */}

      {/* <iframe width="100%" height="544" src="https://strawpoll.com/embed/9w3qgrobd" frameborder="0" allowfullscreen></iframe> */}

      {/* Buy me a Coffee */}
      <div className="border rounded bmac-container mt-3 mb-6 pt-3 pb-4">
        <div className="row justify-content-center">
          <p className="font-weight-bold text-align-center ml-2 mr-2">
            Loving the content? ❤️ <br />
            Support me with a Coffee!
          </p>
          <div className="col-10 pt-1">
            <BuyMeCoffee></BuyMeCoffee>
          </div>
        </div>
      </div>

      <Strawpoll />

      {/* <InlineAffiliateAd id="sidebar" position="sidebar" /> */}

      {/* PRODUCT SHARING */}
      {/* <div className="col-12 white-card sidenav-product-card">
        <img src={book} alt="book" />
        <h4>1000 Essential Words</h4>
        <span>
          Get you FREE eBook to expand your Vietnamese vocabulary power
        </span>
        <button className="btn-outline mr-2">
          <OutboundLink href="https://gum.co/ZFXpQ" target="_blank">
            Download eBook
          </OutboundLink>
        </button>
      </div> */}

      {/* <MailchimpPopup /> */}

      {/* AUTHOR BIO*/}
      {/* <div className="author-wrapper col-12 white-card">
        <div>
          <img src={AuthorPic} />
          <p>
            <h4>Caroline Phung</h4>
            <span className="text-muted">Creator & Teacher</span>
          </p>
        </div>
        <p>
          Hi friends, I’m Caroline. I create HowToVietnamese to help expats,
          travelers and language lovers communicate in authentic Southern
          Vietnamese fast and naturally through practical, bite-sized courses
          and books. Ready to learn Vietnamese? Let's head over to my{" "}
          <a href="/courses">library courses</a>!
        </p>
      </div> */}

      {/* follow me links */}
      {/* <FollowMe /> */}

      {/* single course
        <SingleCourse />*/}
    </div>
  );
}

export default Sidebar;
