import { OutboundLink } from "gatsby-plugin-google-analytics";
import React from "react";
import bmcLogo from "../images/bmc-logo.png";

const bmcIframeStyle = {
  margin: "0px",
  border: "0px",
  height: "400px",
  width: "100%",
  borderRadius: "3px",
  height: "500px",
  boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 4px",
  background:
    'rgb(255, 255, 255) url("https://marketplace.kony.com/static/dist/images/loader.svg") no-repeat scroll center center / 64px; transition: all 0.4s ease 0s',
};

const bmcLogoStyle = {
  width: "20px",
  height: "20px",
  marginRight: "10px",
};

const buttonStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  textDecoration: "none",
  background: "#6381F7",
  fontWeight: "bolder",
  fontSize: "1em",
  padding: "14px 20px",
};

const koFiStyle = {
  border: "0px",
  height: "36px",
};

function BuyMeCoffee(props) {
  // type can be
  // embed or button
  const coffeeType = props.type ? props.type : "link";
  return (
    <>
      {/* <button style={buttonStyle} onClick={() => openKofi()}>
        {" "}
        <img style={bmcLogoStyle} src={bmcLogo}></img>Buy Me a Coffee
      </button> */}
      <a href="https://ko-fi.com/M4M2K8BHZ" target="_blank">
        <img
          height="36"
          style={koFiStyle}
          src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
          border="0"
          alt="Buy Me a Coffee"
        />
      </a>
    </>
    // <React.Fragment>
    //   {coffeeType == "link" ? (
    //     <OutboundLink
    //       target="_blank"
    //       style={buttonStyle}
    //       className="btn-solid box-shadow"
    //       href="https://www.buymeacoffee.com/howtovietnamese"
    //     >
    //       <img style={bmcLogoStyle} src={bmcLogo}></img>
    //       Buy Me a Coffee
    //     </OutboundLink>
    //   ) : (
    //     <iframe
    //       style={bmcIframeStyle}
    //       src="https://www.buymeacoffee.com/widget/page/howtovietnamese?description=Support%20me%20on%20Buy%20me%20a%20coffee!&amp;color=%23FF813F"
    //     ></iframe>
    //   )}
    // </React.Fragment>
  );
}

export default BuyMeCoffee;
